import React from "react";
import Footer from "components/Footer/Footer";
import "./Home.css";

export default function Home() {
  return (
    <div className="Home">
      home
      <Footer />
    </div>
  );
}
